<template>
  <div
  class="app-border mb-3 p-4 d-flex active-element billing-card"
  :class="{'disabled-card': disabled, 'selected-plan': isSelected}"
  @click="planSelectionHandler"
  >
    <div class="w-80">
      <h2 :class="{'current-plan': currentPlan}" class="mb-3">{{ title }}
        <span v-if="disabled" class="pre-md-text"> (monthly only)</span>
        <span v-if="currentPlan && !disabled" class="pre-md-text"> (current plan)</span>
      </h2>
      <p class="mb-4 small-text font-400">{{ description }}</p>
      <p class="mb-0"><i class="small-text">{{ subDescription }}</i></p>
    </div>
    <div v-if="price > 0" class="d-flex flex-column w-20 justify-content-center align-items-center">
      <div class="d-flex justify-content-end align-items-center price">
        <h1>${{ price }}</h1>
        <span class="small-text ml-1">&sol;{{ planType === plansTypes.ANNUAL ? 'year' : 'month' }}</span>
      </div>
      <span v-if="planType === plansTypes.ANNUAL && !disabled"
      class="d-flex justify-content-end align-items-center pr-3 small-text mb-3">(${{price / 12}}&sol;month)</span>
      <b v-if="!!clarification" class="xs-text text-center">{{clarification}}</b>
      <div class="requirements d-flex flex-column">
      <p
        v-for="(req, index) in requirements"
        :key="`req_${index}`"
        class="mb-0 text-center"
      ><i class="xs-text">{{ req }}</i>
      </p>
      </div>
    </div>
    <div v-else class="w-30 d-flex justify-content-end">
      <div class="w-50">
        <app-spinner />
      </div>
    </div>
  </div>
</template>
<script>
import appNotification, {notificationsTypes} from '@/util/appNotification';
import AppSpinner from '@/components/App/UI/AppSpinner.vue';
import {plansTypes} from '@/store/userConstants';

export default {
  components: {AppSpinner},
  data: function() {
    return {
      plansTypes,
    };
  },
  props: {
    title: {
      type: String,
    },
    description: {
      type: String,
    },
    subDescription: {
      type: String,
    },
    requirements: {
      type: Array,
      default: () => [],
    },
    price: {
      type: Number,
    },
    clarification: String,
    accountType: String,
    currentPlan: Boolean,
    disabled: Boolean,
    isSelected: Boolean,
    planType: String,
  },
  methods: {
    planSelectionHandler: function() {
      if (this.disabled) {
        appNotification.notify({message: 'This plan is monthly only', type: notificationsTypes.info});
      } else {
        this.$emit('plan-selected');
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import '@/assets/sass/argon.scss';
@import "@/assets/sass/lbd/mixins/_layout.scss";
.billing-card{
  border-width: 2px;
  transition: all .35s linear;
  p, b, span, i {
      color: $black;
  }
  .requirements {
    p {
      line-height: 1.2;
    }
  }
  .price {
    span {
      margin-bottom: -5px;
    }
    @include respond-to(xs) {
      flex-direction: column;
      margin-bottom: 20px;
    }
  }
  &.disabled-card{
    color: $gray-600;
    h1, h2, p, b, span, i {
      color: $gray-600 !important;
    }
  }
  &.selected-plan{
      border-color: $primary;
      color: $primary;
  }
  .current-plan{
    color: $primary !important;
    span{
      color: $gray-600;
    }
  }
}
</style>
