<template>
    <div class="d-flex align-items-center justify-content-center w-100 h-100">
        <i class="fas fa-spinner fa-spin primary-color text-lg"></i>
    </div>
</template>
<script>
export default {
};
</script>
<style scoped lang="scss">
</style>
